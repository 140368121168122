export enum DashboardMenuOption {
  addContacts = '/contacts/add-contacts',
  accountSettings = '/settings/account',
  admin = '/admin',
  adminContentLibrary = '/admin/library',
  analytics = '/analytics',
  billing = '/settings/billing',
  contacts = '/contacts',
  content = '/content',
  contentLibrary = '/content/library',
  csDashboard = '/cs',
  csArticleActivity = '/cs/article-activity',
  domainAuthentication = '/settings/domain',
  dashboard = '/',
  emailDesign = '/design',
  logOut = '/logout',
  newsletters = '/settings/newsletters',
  preview = '/preview',
  profile = '/settings/profile',
  schedule = '/schedule',
  scheduledContent = '/schedule/scheduled-content',
  suggestContent = '/content/suggest',
  sources = '/content/sources',
  section = '/content/sections',
  settings = '/settings',
  customDesign = '/custom',
  widgets = '/widgets',
  templateLibrary = '/template-library'
}
