import {DashboardMenuOption} from 'components/dashboard-menu/constants'
import { RasaContext } from 'context'
import React, {Component} from 'react'
import {connect} from 'react-redux'
import * as Router from 'router'
import { Roles } from 'shared/constants'
import * as GlobalConstants from '../../constants'
import * as Globals from '../../generic/globals'

import './styles.css'

const DASHBOARD_POPUP_MENU_OPTIONS = {
  faq: GlobalConstants.RASA_HELP_BASE_URL,
  press: GlobalConstants.RASA_PRESS_URL,
  rasaHome: GlobalConstants.RASA_HOME_URL,
  privacy: GlobalConstants.RASA_PRIVACY_URL,
}

interface DashboardPopupMenuOptionProps {
  onChange?: any,
  onClose?: any,
  push: any,
}
class DashboardPopupMenuComponent extends Component<DashboardPopupMenuOptionProps> {
  public static contextType = RasaContext
  constructor(props: DashboardPopupMenuOptionProps) {
    super(props)
  }

  public render() {
    const { activeCommunity, person } = this.context.user
    if (activeCommunity && activeCommunity.communityInfo && person && person.accountInfo) {
      return (
          <div className="dashboard-popup-menu">
            <a className="dashboard-popup-menu-close"
              href="#"
              onClick={() => {
                        if (this.props.onClose) {
                          this.props.onClose()
                        }}}></a>
            <div className="dashboard-popup-menu-user-info">
              <div className={this.context.user.activeCommunity
                && this.context.user.activeCommunity.data
                && this.context.user.activeCommunity.data.community_account_suspended === 1
                && activeCommunity.role !== Roles.super_admin ? 'account-suspended' : ''}>
                <div className="dashboard-popup-menu-user-name">{person.fullName}</div>
                <div className="dashboard-popup-menu-user-data">
                  {activeCommunity.communityDisplayName || activeCommunity.communityName}
                </div>
                {/* <div className="dashboard-popup-menu-user-role">{this.context.user.activeCommunity.role}</div> */}
                <div className="dashboard-popup-menu-signed-in-as">
                    You're signed in as&nbsp;
                    <span className="dashboard-popup-menu-signed-in-as-email">{person.email}</span>
                </div>
                {/* Multiple Newsletter Access */}
                {(activeCommunity.role === Roles.super_admin ||
                  person.hasMultipleNewsletterAccess ||
                  activeCommunity.billingInfo.usageStats.numNewsletters > 1) && <div>
                  <div className="dashboard-popup-menu-horizontal-divider"></div>
                  <div className="dashboard-popup-menu-space clickable-item"
                        onClick={() => this.handleClick(DashboardMenuOption.newsletters)}>
                      Manage newsletters
                  </div>
                  <div className="dashboard-popup-menu-horizontal-divider"></div>
                </div>
                }
                {/* Super Admin */}
                {activeCommunity.role === Roles.super_admin && <div>
                  <div className="dashboard-popup-menu-space clickable-item"
                    onClick={() => this.handleClick(DashboardMenuOption.adminContentLibrary)}>Administration</div>
                  <div className="dashboard-popup-menu-space clickable-item"
                    onClick={() => this.handleClick(DashboardMenuOption.csArticleActivity)}>CS Toolbox</div>
                  <div className="dashboard-popup-menu-horizontal-divider"></div>
                </div>
                }
                <br/>
                <div className="dashboard-popup-menu-item clickable-item">
                  <a href={DASHBOARD_POPUP_MENU_OPTIONS.faq} target="_blank" rel="noopener">
                    Help & Support
                  </a>
                </div>
                {/* <div className="dashboard-popup-menu-item clickable-item">
                  <a href="https://app.productstash.io/rasaio#/roadmap" target="_blank" rel="noopener">
                    Roadmap
                  </a>
                </div> */}
                <div className="dashboard-popup-menu-item clickable-item">
                  <a href={DASHBOARD_POPUP_MENU_OPTIONS.press} target="_blank" rel="noopener">
                    News
                  </a>
                </div>
                <div className="dashboard-popup-menu-item clickable-item">
                  <a href={DASHBOARD_POPUP_MENU_OPTIONS.rasaHome} target="_blank" rel="noopener">
                    {GlobalConstants.BRAND_NAME}
                  </a>
                </div>
                <div className="dashboard-popup-menu-item clickable-item">
                  <a href={DASHBOARD_POPUP_MENU_OPTIONS.privacy} target="_blank" rel="noopener">
                    Privacy Policy
                  </a>
                </div>
                <div className="dashboard-popup-menu-horizontal-divider"></div>
              </div>
              <div>
              <div className="dashboard-popup-menu-space clickable-item"
                    onClick={() => this.handleClick(DashboardMenuOption.logOut)}>
                    <img className="dashboard-popup-menu-small-icon" src={Globals.LOGOUT_IMG}></img>
                    Log out
                </div>
                <div className="dashboard-popup-menu-version-info" >Version 2.1</div>
              </div>
            </div>
          </div>
      )
    } else {
      return null
    }
  }

  private handleClick(item: string) {
    this.props.push(item);
    if (this.props.onChange) {
      this.props.onChange(item)
    }
  }
}

export const DashboardPopupMenu = connect(
  null,
  {
    push: Router.push,
  },
)(DashboardPopupMenuComponent)
